// @generated by protoc-gen-es v1.3.2
// @generated from file resemble/v1alpha1/tasks.proto (package resemble.v1alpha1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Any, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message resemble.v1alpha1.TaskId
 */
export const TaskId = proto3.makeMessageType(
  "resemble.v1alpha1.TaskId",
  () => [
    { no: 1, name: "state_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "state_ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "task_uuid", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * @generated from message resemble.v1alpha1.WaitRequest
 */
export const WaitRequest = proto3.makeMessageType(
  "resemble.v1alpha1.WaitRequest",
  () => [
    { no: 1, name: "task_id", kind: "message", T: TaskId },
  ],
);

/**
 * @generated from message resemble.v1alpha1.TaskResponseOrError
 */
export const TaskResponseOrError = proto3.makeMessageType(
  "resemble.v1alpha1.TaskResponseOrError",
  () => [
    { no: 1, name: "response", kind: "message", T: Any, oneof: "response_or_error" },
    { no: 2, name: "error", kind: "message", T: Any, oneof: "response_or_error" },
  ],
);

/**
 * @generated from message resemble.v1alpha1.WaitResponse
 */
export const WaitResponse = proto3.makeMessageType(
  "resemble.v1alpha1.WaitResponse",
  () => [
    { no: 1, name: "response_or_error", kind: "message", T: TaskResponseOrError },
  ],
);

/**
 * @generated from message resemble.v1alpha1.ListPendingTasksRequest
 */
export const ListPendingTasksRequest = proto3.makeMessageType(
  "resemble.v1alpha1.ListPendingTasksRequest",
  () => [
    { no: 1, name: "only_consensus_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * @generated from message resemble.v1alpha1.ListPendingTasksResponse
 */
export const ListPendingTasksResponse = proto3.makeMessageType(
  "resemble.v1alpha1.ListPendingTasksResponse",
  () => [
    { no: 1, name: "task_ids", kind: "message", T: TaskId, repeated: true },
  ],
);

/**
 * @generated from message resemble.v1alpha1.CancelTaskRequest
 */
export const CancelTaskRequest = proto3.makeMessageType(
  "resemble.v1alpha1.CancelTaskRequest",
  () => [
    { no: 1, name: "task_id", kind: "message", T: TaskId },
  ],
);

/**
 * @generated from message resemble.v1alpha1.CancelTaskResponse
 */
export const CancelTaskResponse = proto3.makeMessageType(
  "resemble.v1alpha1.CancelTaskResponse",
  () => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(CancelTaskResponse_Status) },
  ],
);

/**
 * @generated from enum resemble.v1alpha1.CancelTaskResponse.Status
 */
export const CancelTaskResponse_Status = proto3.makeEnum(
  "resemble.v1alpha1.CancelTaskResponse.Status",
  [
    {no: 0, name: "OK"},
    {no: 1, name: "NOT_FOUND"},
    {no: 2, name: "CANCELLING"},
  ],
);

/**
 * @generated from message resemble.v1alpha1.TaskCancelledError
 */
export const TaskCancelledError = proto3.makeMessageType(
  "resemble.v1alpha1.TaskCancelledError",
  [],
);

