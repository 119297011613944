"use client";
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _UsersAddAborted_message, _UserSignUpAborted_message, _UserSetSurveyDataAborted_message, _UserSetStatusAborted_message, _UserActivateTaskAborted_message, _UserGetAborted_message;
import { Empty } from "@bufbuild/protobuf";
import * as resemble_react from "@reboot-dev/resemble-react";
import * as resemble_api from "@reboot-dev/resemble-api";
import { useEffect, useMemo, useState, } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { v4 as uuidv4 } from "uuid";
import { SurveyData, User as UserProto, Users as UsersProto, AddRequest, AddResponse, InvalidInputError, SignUpRequest, SignUpResponse, SetSurveyDataRequest, SetSurveyDataResponse, SetStatusRequest, SetStatusResponse, ActivateTaskResponse, GetRequest, GetResponse, } from "./user_pb.js";
// Additionally re-export all messages_and_enums from the pb module.
export { SurveyData, AddRequest, AddResponse, InvalidInputError, SignUpRequest, SignUpResponse, SetSurveyDataRequest, SetSurveyDataResponse, SetStatusRequest, SetStatusResponse, ActivateTaskResponse, GetRequest, GetResponse, };
const USERS_ADD_ERROR_TYPES = [
    // TODO(benh): don't copy these errors everywhere.
    //
    // gRPC errors.
    resemble_api.errors_pb.Cancelled,
    resemble_api.errors_pb.Unknown,
    resemble_api.errors_pb.InvalidArgument,
    resemble_api.errors_pb.DeadlineExceeded,
    resemble_api.errors_pb.NotFound,
    resemble_api.errors_pb.AlreadyExists,
    resemble_api.errors_pb.PermissionDenied,
    resemble_api.errors_pb.ResourceExhausted,
    resemble_api.errors_pb.FailedPrecondition,
    resemble_api.errors_pb.Aborted,
    resemble_api.errors_pb.OutOfRange,
    resemble_api.errors_pb.Unimplemented,
    resemble_api.errors_pb.Internal,
    resemble_api.errors_pb.Unavailable,
    resemble_api.errors_pb.DataLoss,
    resemble_api.errors_pb.Unauthenticated,
    // Resemble errors.
    //
    // NOTE: also add any new errors into `resemble/v1alpha1/index.ts`.
    resemble_api.errors_pb.StateAlreadyConstructed,
    resemble_api.errors_pb.StateNotConstructed,
    resemble_api.errors_pb.TransactionParticipantFailedToPrepare,
    resemble_api.errors_pb.TransactionParticipantFailedToCommit,
    resemble_api.errors_pb.UnknownService,
    resemble_api.errors_pb.UnknownTask,
    // Method errors.
]; // Need `as const` to ensure TypeScript infers this as a tuple!
export class UsersAddAborted extends resemble_api.Aborted {
    static fromStatus(status) {
        let error = resemble_api.errorFromGoogleRpcStatusDetails(status, USERS_ADD_ERROR_TYPES);
        if (error !== undefined) {
            return new UsersAddAborted(error, { message: status.message });
        }
        error = resemble_api.errorFromGoogleRpcStatusCode(status);
        // TODO(benh): also consider getting the type names from
        // `status.details` and including that in `message` to make
        // debugging easier.
        return new UsersAddAborted(error, { message: status.message });
    }
    toStatus() {
        const isObject = (value) => {
            return typeof value === 'object';
        };
        const isArray = (value) => {
            return Array.isArray(value);
        };
        const error = this.error.toJson();
        if (!isObject(error) || isArray(error)) {
            throw new Error("Expecting 'error' to be an object (and not an array)");
        }
        const detail = { ...error };
        detail["@type"] = `type.googleapis.com/${this.error.getType().typeName}`;
        return new resemble_api.Status({
            code: this.code,
            message: __classPrivateFieldGet(this, _UsersAddAborted_message, "f"),
            details: [detail]
        });
    }
    constructor(error, { message } = {}) {
        super();
        _UsersAddAborted_message.set(this, void 0);
        // Set the name of this error for even more information!
        this.name = this.constructor.name;
        this.error = error;
        let code = resemble_api.grpcStatusCodeFromError(this.error);
        if (code === undefined) {
            // Must be one of the Resemble specific errors.
            code = resemble_api.StatusCode.ABORTED;
        }
        this.code = code;
        __classPrivateFieldSet(this, _UsersAddAborted_message, message, "f");
    }
    toString() {
        return `${this.name}: ${this.message}`;
    }
    get message() {
        return `${this.error.getType().typeName}${__classPrivateFieldGet(this, _UsersAddAborted_message, "f") ? " with message " + __classPrivateFieldGet(this, _UsersAddAborted_message, "f") : ""}`;
    }
}
_UsersAddAborted_message = new WeakMap();
const USER_SIGN_UP_ERROR_TYPES = [
    // TODO(benh): don't copy these errors everywhere.
    //
    // gRPC errors.
    resemble_api.errors_pb.Cancelled,
    resemble_api.errors_pb.Unknown,
    resemble_api.errors_pb.InvalidArgument,
    resemble_api.errors_pb.DeadlineExceeded,
    resemble_api.errors_pb.NotFound,
    resemble_api.errors_pb.AlreadyExists,
    resemble_api.errors_pb.PermissionDenied,
    resemble_api.errors_pb.ResourceExhausted,
    resemble_api.errors_pb.FailedPrecondition,
    resemble_api.errors_pb.Aborted,
    resemble_api.errors_pb.OutOfRange,
    resemble_api.errors_pb.Unimplemented,
    resemble_api.errors_pb.Internal,
    resemble_api.errors_pb.Unavailable,
    resemble_api.errors_pb.DataLoss,
    resemble_api.errors_pb.Unauthenticated,
    // Resemble errors.
    //
    // NOTE: also add any new errors into `resemble/v1alpha1/index.ts`.
    resemble_api.errors_pb.StateAlreadyConstructed,
    resemble_api.errors_pb.StateNotConstructed,
    resemble_api.errors_pb.TransactionParticipantFailedToPrepare,
    resemble_api.errors_pb.TransactionParticipantFailedToCommit,
    resemble_api.errors_pb.UnknownService,
    resemble_api.errors_pb.UnknownTask,
    // Method errors.
]; // Need `as const` to ensure TypeScript infers this as a tuple!
export class UserSignUpAborted extends resemble_api.Aborted {
    static fromStatus(status) {
        let error = resemble_api.errorFromGoogleRpcStatusDetails(status, USER_SIGN_UP_ERROR_TYPES);
        if (error !== undefined) {
            return new UserSignUpAborted(error, { message: status.message });
        }
        error = resemble_api.errorFromGoogleRpcStatusCode(status);
        // TODO(benh): also consider getting the type names from
        // `status.details` and including that in `message` to make
        // debugging easier.
        return new UserSignUpAborted(error, { message: status.message });
    }
    toStatus() {
        const isObject = (value) => {
            return typeof value === 'object';
        };
        const isArray = (value) => {
            return Array.isArray(value);
        };
        const error = this.error.toJson();
        if (!isObject(error) || isArray(error)) {
            throw new Error("Expecting 'error' to be an object (and not an array)");
        }
        const detail = { ...error };
        detail["@type"] = `type.googleapis.com/${this.error.getType().typeName}`;
        return new resemble_api.Status({
            code: this.code,
            message: __classPrivateFieldGet(this, _UserSignUpAborted_message, "f"),
            details: [detail]
        });
    }
    constructor(error, { message } = {}) {
        super();
        _UserSignUpAborted_message.set(this, void 0);
        // Set the name of this error for even more information!
        this.name = this.constructor.name;
        this.error = error;
        let code = resemble_api.grpcStatusCodeFromError(this.error);
        if (code === undefined) {
            // Must be one of the Resemble specific errors.
            code = resemble_api.StatusCode.ABORTED;
        }
        this.code = code;
        __classPrivateFieldSet(this, _UserSignUpAborted_message, message, "f");
    }
    toString() {
        return `${this.name}: ${this.message}`;
    }
    get message() {
        return `${this.error.getType().typeName}${__classPrivateFieldGet(this, _UserSignUpAborted_message, "f") ? " with message " + __classPrivateFieldGet(this, _UserSignUpAborted_message, "f") : ""}`;
    }
}
_UserSignUpAborted_message = new WeakMap();
const USER_SET_SURVEY_DATA_ERROR_TYPES = [
    // TODO(benh): don't copy these errors everywhere.
    //
    // gRPC errors.
    resemble_api.errors_pb.Cancelled,
    resemble_api.errors_pb.Unknown,
    resemble_api.errors_pb.InvalidArgument,
    resemble_api.errors_pb.DeadlineExceeded,
    resemble_api.errors_pb.NotFound,
    resemble_api.errors_pb.AlreadyExists,
    resemble_api.errors_pb.PermissionDenied,
    resemble_api.errors_pb.ResourceExhausted,
    resemble_api.errors_pb.FailedPrecondition,
    resemble_api.errors_pb.Aborted,
    resemble_api.errors_pb.OutOfRange,
    resemble_api.errors_pb.Unimplemented,
    resemble_api.errors_pb.Internal,
    resemble_api.errors_pb.Unavailable,
    resemble_api.errors_pb.DataLoss,
    resemble_api.errors_pb.Unauthenticated,
    // Resemble errors.
    //
    // NOTE: also add any new errors into `resemble/v1alpha1/index.ts`.
    resemble_api.errors_pb.StateAlreadyConstructed,
    resemble_api.errors_pb.StateNotConstructed,
    resemble_api.errors_pb.TransactionParticipantFailedToPrepare,
    resemble_api.errors_pb.TransactionParticipantFailedToCommit,
    resemble_api.errors_pb.UnknownService,
    resemble_api.errors_pb.UnknownTask,
    // Method errors.
    InvalidInputError,
]; // Need `as const` to ensure TypeScript infers this as a tuple!
export class UserSetSurveyDataAborted extends resemble_api.Aborted {
    static fromStatus(status) {
        let error = resemble_api.errorFromGoogleRpcStatusDetails(status, USER_SET_SURVEY_DATA_ERROR_TYPES);
        if (error !== undefined) {
            return new UserSetSurveyDataAborted(error, { message: status.message });
        }
        error = resemble_api.errorFromGoogleRpcStatusCode(status);
        // TODO(benh): also consider getting the type names from
        // `status.details` and including that in `message` to make
        // debugging easier.
        return new UserSetSurveyDataAborted(error, { message: status.message });
    }
    toStatus() {
        const isObject = (value) => {
            return typeof value === 'object';
        };
        const isArray = (value) => {
            return Array.isArray(value);
        };
        const error = this.error.toJson();
        if (!isObject(error) || isArray(error)) {
            throw new Error("Expecting 'error' to be an object (and not an array)");
        }
        const detail = { ...error };
        detail["@type"] = `type.googleapis.com/${this.error.getType().typeName}`;
        return new resemble_api.Status({
            code: this.code,
            message: __classPrivateFieldGet(this, _UserSetSurveyDataAborted_message, "f"),
            details: [detail]
        });
    }
    constructor(error, { message } = {}) {
        super();
        _UserSetSurveyDataAborted_message.set(this, void 0);
        // Set the name of this error for even more information!
        this.name = this.constructor.name;
        this.error = error;
        let code = resemble_api.grpcStatusCodeFromError(this.error);
        if (code === undefined) {
            // Must be one of the Resemble specific errors.
            code = resemble_api.StatusCode.ABORTED;
        }
        this.code = code;
        __classPrivateFieldSet(this, _UserSetSurveyDataAborted_message, message, "f");
    }
    toString() {
        return `${this.name}: ${this.message}`;
    }
    get message() {
        return `${this.error.getType().typeName}${__classPrivateFieldGet(this, _UserSetSurveyDataAborted_message, "f") ? " with message " + __classPrivateFieldGet(this, _UserSetSurveyDataAborted_message, "f") : ""}`;
    }
}
_UserSetSurveyDataAborted_message = new WeakMap();
const USER_SET_STATUS_ERROR_TYPES = [
    // TODO(benh): don't copy these errors everywhere.
    //
    // gRPC errors.
    resemble_api.errors_pb.Cancelled,
    resemble_api.errors_pb.Unknown,
    resemble_api.errors_pb.InvalidArgument,
    resemble_api.errors_pb.DeadlineExceeded,
    resemble_api.errors_pb.NotFound,
    resemble_api.errors_pb.AlreadyExists,
    resemble_api.errors_pb.PermissionDenied,
    resemble_api.errors_pb.ResourceExhausted,
    resemble_api.errors_pb.FailedPrecondition,
    resemble_api.errors_pb.Aborted,
    resemble_api.errors_pb.OutOfRange,
    resemble_api.errors_pb.Unimplemented,
    resemble_api.errors_pb.Internal,
    resemble_api.errors_pb.Unavailable,
    resemble_api.errors_pb.DataLoss,
    resemble_api.errors_pb.Unauthenticated,
    // Resemble errors.
    //
    // NOTE: also add any new errors into `resemble/v1alpha1/index.ts`.
    resemble_api.errors_pb.StateAlreadyConstructed,
    resemble_api.errors_pb.StateNotConstructed,
    resemble_api.errors_pb.TransactionParticipantFailedToPrepare,
    resemble_api.errors_pb.TransactionParticipantFailedToCommit,
    resemble_api.errors_pb.UnknownService,
    resemble_api.errors_pb.UnknownTask,
    // Method errors.
    InvalidInputError,
]; // Need `as const` to ensure TypeScript infers this as a tuple!
export class UserSetStatusAborted extends resemble_api.Aborted {
    static fromStatus(status) {
        let error = resemble_api.errorFromGoogleRpcStatusDetails(status, USER_SET_STATUS_ERROR_TYPES);
        if (error !== undefined) {
            return new UserSetStatusAborted(error, { message: status.message });
        }
        error = resemble_api.errorFromGoogleRpcStatusCode(status);
        // TODO(benh): also consider getting the type names from
        // `status.details` and including that in `message` to make
        // debugging easier.
        return new UserSetStatusAborted(error, { message: status.message });
    }
    toStatus() {
        const isObject = (value) => {
            return typeof value === 'object';
        };
        const isArray = (value) => {
            return Array.isArray(value);
        };
        const error = this.error.toJson();
        if (!isObject(error) || isArray(error)) {
            throw new Error("Expecting 'error' to be an object (and not an array)");
        }
        const detail = { ...error };
        detail["@type"] = `type.googleapis.com/${this.error.getType().typeName}`;
        return new resemble_api.Status({
            code: this.code,
            message: __classPrivateFieldGet(this, _UserSetStatusAborted_message, "f"),
            details: [detail]
        });
    }
    constructor(error, { message } = {}) {
        super();
        _UserSetStatusAborted_message.set(this, void 0);
        // Set the name of this error for even more information!
        this.name = this.constructor.name;
        this.error = error;
        let code = resemble_api.grpcStatusCodeFromError(this.error);
        if (code === undefined) {
            // Must be one of the Resemble specific errors.
            code = resemble_api.StatusCode.ABORTED;
        }
        this.code = code;
        __classPrivateFieldSet(this, _UserSetStatusAborted_message, message, "f");
    }
    toString() {
        return `${this.name}: ${this.message}`;
    }
    get message() {
        return `${this.error.getType().typeName}${__classPrivateFieldGet(this, _UserSetStatusAborted_message, "f") ? " with message " + __classPrivateFieldGet(this, _UserSetStatusAborted_message, "f") : ""}`;
    }
}
_UserSetStatusAborted_message = new WeakMap();
const USER_ACTIVATE_TASK_ERROR_TYPES = [
    // TODO(benh): don't copy these errors everywhere.
    //
    // gRPC errors.
    resemble_api.errors_pb.Cancelled,
    resemble_api.errors_pb.Unknown,
    resemble_api.errors_pb.InvalidArgument,
    resemble_api.errors_pb.DeadlineExceeded,
    resemble_api.errors_pb.NotFound,
    resemble_api.errors_pb.AlreadyExists,
    resemble_api.errors_pb.PermissionDenied,
    resemble_api.errors_pb.ResourceExhausted,
    resemble_api.errors_pb.FailedPrecondition,
    resemble_api.errors_pb.Aborted,
    resemble_api.errors_pb.OutOfRange,
    resemble_api.errors_pb.Unimplemented,
    resemble_api.errors_pb.Internal,
    resemble_api.errors_pb.Unavailable,
    resemble_api.errors_pb.DataLoss,
    resemble_api.errors_pb.Unauthenticated,
    // Resemble errors.
    //
    // NOTE: also add any new errors into `resemble/v1alpha1/index.ts`.
    resemble_api.errors_pb.StateAlreadyConstructed,
    resemble_api.errors_pb.StateNotConstructed,
    resemble_api.errors_pb.TransactionParticipantFailedToPrepare,
    resemble_api.errors_pb.TransactionParticipantFailedToCommit,
    resemble_api.errors_pb.UnknownService,
    resemble_api.errors_pb.UnknownTask,
    // Method errors.
]; // Need `as const` to ensure TypeScript infers this as a tuple!
export class UserActivateTaskAborted extends resemble_api.Aborted {
    static fromStatus(status) {
        let error = resemble_api.errorFromGoogleRpcStatusDetails(status, USER_ACTIVATE_TASK_ERROR_TYPES);
        if (error !== undefined) {
            return new UserActivateTaskAborted(error, { message: status.message });
        }
        error = resemble_api.errorFromGoogleRpcStatusCode(status);
        // TODO(benh): also consider getting the type names from
        // `status.details` and including that in `message` to make
        // debugging easier.
        return new UserActivateTaskAborted(error, { message: status.message });
    }
    toStatus() {
        const isObject = (value) => {
            return typeof value === 'object';
        };
        const isArray = (value) => {
            return Array.isArray(value);
        };
        const error = this.error.toJson();
        if (!isObject(error) || isArray(error)) {
            throw new Error("Expecting 'error' to be an object (and not an array)");
        }
        const detail = { ...error };
        detail["@type"] = `type.googleapis.com/${this.error.getType().typeName}`;
        return new resemble_api.Status({
            code: this.code,
            message: __classPrivateFieldGet(this, _UserActivateTaskAborted_message, "f"),
            details: [detail]
        });
    }
    constructor(error, { message } = {}) {
        super();
        _UserActivateTaskAborted_message.set(this, void 0);
        // Set the name of this error for even more information!
        this.name = this.constructor.name;
        this.error = error;
        let code = resemble_api.grpcStatusCodeFromError(this.error);
        if (code === undefined) {
            // Must be one of the Resemble specific errors.
            code = resemble_api.StatusCode.ABORTED;
        }
        this.code = code;
        __classPrivateFieldSet(this, _UserActivateTaskAborted_message, message, "f");
    }
    toString() {
        return `${this.name}: ${this.message}`;
    }
    get message() {
        return `${this.error.getType().typeName}${__classPrivateFieldGet(this, _UserActivateTaskAborted_message, "f") ? " with message " + __classPrivateFieldGet(this, _UserActivateTaskAborted_message, "f") : ""}`;
    }
}
_UserActivateTaskAborted_message = new WeakMap();
const USER_GET_ERROR_TYPES = [
    // TODO(benh): don't copy these errors everywhere.
    //
    // gRPC errors.
    resemble_api.errors_pb.Cancelled,
    resemble_api.errors_pb.Unknown,
    resemble_api.errors_pb.InvalidArgument,
    resemble_api.errors_pb.DeadlineExceeded,
    resemble_api.errors_pb.NotFound,
    resemble_api.errors_pb.AlreadyExists,
    resemble_api.errors_pb.PermissionDenied,
    resemble_api.errors_pb.ResourceExhausted,
    resemble_api.errors_pb.FailedPrecondition,
    resemble_api.errors_pb.Aborted,
    resemble_api.errors_pb.OutOfRange,
    resemble_api.errors_pb.Unimplemented,
    resemble_api.errors_pb.Internal,
    resemble_api.errors_pb.Unavailable,
    resemble_api.errors_pb.DataLoss,
    resemble_api.errors_pb.Unauthenticated,
    // Resemble errors.
    //
    // NOTE: also add any new errors into `resemble/v1alpha1/index.ts`.
    resemble_api.errors_pb.StateAlreadyConstructed,
    resemble_api.errors_pb.StateNotConstructed,
    resemble_api.errors_pb.TransactionParticipantFailedToPrepare,
    resemble_api.errors_pb.TransactionParticipantFailedToCommit,
    resemble_api.errors_pb.UnknownService,
    resemble_api.errors_pb.UnknownTask,
    // Method errors.
]; // Need `as const` to ensure TypeScript infers this as a tuple!
export class UserGetAborted extends resemble_api.Aborted {
    static fromStatus(status) {
        let error = resemble_api.errorFromGoogleRpcStatusDetails(status, USER_GET_ERROR_TYPES);
        if (error !== undefined) {
            return new UserGetAborted(error, { message: status.message });
        }
        error = resemble_api.errorFromGoogleRpcStatusCode(status);
        // TODO(benh): also consider getting the type names from
        // `status.details` and including that in `message` to make
        // debugging easier.
        return new UserGetAborted(error, { message: status.message });
    }
    toStatus() {
        const isObject = (value) => {
            return typeof value === 'object';
        };
        const isArray = (value) => {
            return Array.isArray(value);
        };
        const error = this.error.toJson();
        if (!isObject(error) || isArray(error)) {
            throw new Error("Expecting 'error' to be an object (and not an array)");
        }
        const detail = { ...error };
        detail["@type"] = `type.googleapis.com/${this.error.getType().typeName}`;
        return new resemble_api.Status({
            code: this.code,
            message: __classPrivateFieldGet(this, _UserGetAborted_message, "f"),
            details: [detail]
        });
    }
    constructor(error, { message } = {}) {
        super();
        _UserGetAborted_message.set(this, void 0);
        // Set the name of this error for even more information!
        this.name = this.constructor.name;
        this.error = error;
        let code = resemble_api.grpcStatusCodeFromError(this.error);
        if (code === undefined) {
            // Must be one of the Resemble specific errors.
            code = resemble_api.StatusCode.ABORTED;
        }
        this.code = code;
        __classPrivateFieldSet(this, _UserGetAborted_message, message, "f");
    }
    toString() {
        return `${this.name}: ${this.message}`;
    }
    get message() {
        return `${this.error.getType().typeName}${__classPrivateFieldGet(this, _UserGetAborted_message, "f") ? " with message " + __classPrivateFieldGet(this, _UserGetAborted_message, "f") : ""}`;
    }
}
_UserGetAborted_message = new WeakMap();
class UsersInstance {
    constructor(id, stateRef, endpoint) {
        this.observers = {};
        this.loadingReaders = 0;
        this.runningMutates = [];
        this.queuedMutates = [];
        this.flushMutates = undefined;
        this.websocket = undefined;
        this.backoff = new resemble_react.Backoff();
        this.websocketsConnectionAbortController = new AbortController();
        this.useAddMutations = [];
        this.useAddSetPendings = {};
        this.id = id;
        this.stateRef = stateRef;
        this.endpoint = endpoint;
        this.refs = 1;
        // TODO(benh): rather than keeping a long-lived open connection
        // via `WebSocketsConnection`, we could consider aborting that
        // connection once the websocket is established.
        this.initializeWebSocketsConnection();
        this.initializeWebSocket();
    }
    ref() {
        this.refs += 1;
        return this.refs;
    }
    unref() {
        this.refs -= 1;
        if (this.refs === 0 && this.websocket !== undefined) {
            this.websocket.close();
            this.websocketsConnectionAbortController.abort();
        }
        return this.refs;
    }
    hasRunningMutations() {
        return this.runningMutates.length > 0;
    }
    async flushMutations() {
        if (this.flushMutates === undefined) {
            this.flushMutates = new resemble_react.Event();
        }
        await this.flushMutates.wait();
    }
    readersLoadedOrFailed() {
        var _a;
        this.flushMutates = undefined;
        if (this.queuedMutates.length > 0) {
            this.runningMutates = this.queuedMutates;
            this.queuedMutates = [];
            if (((_a = this.websocket) === null || _a === void 0 ? void 0 : _a.readyState) === WebSocket.OPEN) {
                for (const { request, update } of this.runningMutates) {
                    update({ isLoading: true });
                    try {
                        this.websocket.send(request.toBinary());
                    }
                    catch (e) {
                        // We'll retry since we've stored in `*Mutates`.
                    }
                }
            }
        }
    }
    initializeWebSocketsConnection() {
        resemble_react.retryForever(async () => {
            const headers = new Headers();
            headers.set("Content-Type", "application/json");
            headers.append("Connection", "keep-alive");
            // NOTE: we use `fetch()` not `resemble_react.guardedFetch()`
            // because if there are only mutations than a disconnect will
            // cause us to show a warning when using `rsm dev` but we won't
            // ever remove the warning because the fetch to
            // `WebSocketsConnection` waits indefinitely.
            await fetch(new Request(`${this.endpoint}/__/resemble/resemble.cloud.v1alpha1.user.UsersInterface/${this.stateRef}/resemble.v1alpha1.React/WebSocketsConnection`, {
                method: "POST",
                headers,
                body: new resemble_api.react_pb.WebSocketsConnectionRequest()
                    .toJsonString(),
            }), { signal: this.websocketsConnectionAbortController.signal }).catch((error) => {
                // In some legitimate cases,
                // this.websocketsConnectionAbortController.abort() will be called
                // before fetch() finishes, like on quick unmount.
                // Suppress this error, reraise everything else.
                if (error instanceof Error && error.name !== "AbortError") {
                    throw (error);
                }
            });
        });
    }
    initializeWebSocket() {
        if (this.websocket === undefined && this.refs > 0) {
            const url = new URL(this.endpoint);
            const protocol = url.protocol === "https:" ? "wss:" : "ws:";
            this.websocket = new WebSocket(`${protocol}//${url.host}/__/resemble/resemble.cloud.v1alpha1.user.UsersInterface/${this.stateRef}`);
            this.websocket.binaryType = "arraybuffer";
            this.websocket.onopen = (event) => {
                var _a;
                if (((_a = this.websocket) === null || _a === void 0 ? void 0 : _a.readyState) === WebSocket.OPEN) {
                    for (const { request, update } of this.runningMutates) {
                        update({ isLoading: true });
                        try {
                            this.websocket.send(request.toBinary());
                        }
                        catch (e) {
                            // We'll retry since we've stored in `*Mutates`.
                        }
                    }
                }
            };
            this.websocket.onerror = async (event) => {
                if (this.websocket !== undefined) {
                    // TODO: explicitly close?
                    this.websocket = undefined;
                    for (const { update } of this.runningMutates) {
                        update({ isLoading: false, error: "WebSocket disconnected" });
                    }
                    if (this.refs > 0) {
                        if (this.runningMutates.length > 0) {
                            console.warn(`WebSocket disconnected, ${this.runningMutates.length} outstanding mutations will be retried when we reconnect`);
                        }
                        await this.backoff.wait();
                        this.initializeWebSocket();
                    }
                }
            };
            this.websocket.onclose = async (event) => {
                if (this.websocket !== undefined) {
                    // TODO: explicitly close?
                    this.websocket = undefined;
                    for (const { update } of this.runningMutates) {
                        update({ isLoading: false, error: "WebSocket disconnected" });
                    }
                    if (this.refs > 0) {
                        await this.backoff.wait();
                        this.initializeWebSocket();
                    }
                }
            };
            this.websocket.onmessage = async (event) => {
                const { resolve } = this.runningMutates[0];
                this.runningMutates.shift();
                const response = resemble_api.react_pb.MutateResponse.fromBinary(new Uint8Array(event.data));
                resolve(response);
                if (this.flushMutates !== undefined &&
                    this.runningMutates.length === 0) {
                    this.flushMutates.set();
                }
            };
        }
    }
    async mutate(partialRequest, update) {
        const request = partialRequest instanceof resemble_api.react_pb.MutateRequest
            ? partialRequest
            : new resemble_api.react_pb.MutateRequest(partialRequest);
        return new Promise((resolve, _) => {
            var _a;
            if (this.loadingReaders === 0) {
                this.runningMutates.push({ request, resolve, update });
                if (((_a = this.websocket) === null || _a === void 0 ? void 0 : _a.readyState) === WebSocket.OPEN) {
                    update({ isLoading: true });
                    try {
                        this.websocket.send(request.toBinary());
                    }
                    catch (e) {
                        // We'll retry since we've stored in `*Mutates`.
                    }
                }
            }
            else {
                this.queuedMutates.push({ request, resolve, update });
            }
        });
    }
    async read(method, request, bearerToken, responseType, reader) {
        const headers = new Headers();
        headers.set("Content-Type", "application/json");
        headers.append("Connection", "keep-alive");
        if (bearerToken !== undefined) {
            headers.append("Authorization", `Bearer ${bearerToken}`);
        }
        const queryRequest = new resemble_api.react_pb.QueryRequest({
            method,
            request: request.toBinary(),
        });
        let expecteds = [];
        // When we disconnect we may not be able to observe
        // responses due to mutations yet there may still be
        // some outstanding responses that are expected which
        // we treat as "orphans" in the sense that we won't
        // observe their idempotency keys but once we reconnect
        // we will still have observed their effects and can
        // call `observed()` on them.
        let orphans = [];
        const id = `${uuidv4()}`;
        this.observers[id] = {
            observe: (idempotencyKey, observed, aborted) => {
                expecteds.push({ idempotencyKey, observed, aborted });
            },
            unobserve: (idempotencyKey) => {
                expecteds = expecteds.filter(expected => expected.idempotencyKey !== idempotencyKey);
            }
        };
        try {
            await resemble_react.retryForever(async () => {
                let loaded = false;
                this.loadingReaders += 1;
                // Any mutations started after we've incremented
                // `this.loadingReaders` will be queued until after
                // all the readers have loaded and thus (1) we know all
                // current `expected` are actually `orphans` that
                // we will haved "observed" once we are (re)connected
                // because we flush mutations before starting to read
                // and (2) all queued mutations can stay in `expected`
                // because we will in fact be able to observe them
                // since they won't get sent over the websocket
                // until after we are (re)connected.
                //
                // NOTE: we need to concatenate with `orphans`
                // because we may try to (re)connect multiple times
                // and between each try more mutations may have been
                // made (or queued ones will be moved to running).
                orphans = [...orphans, ...expecteds];
                expecteds = [];
                try {
                    // Wait for potentially completed mutations to flush
                    // before starting to read so that we read the latest
                    // state including those mutations.
                    if (this.hasRunningMutations()) {
                        await this.flushMutations();
                    }
                    unstable_batchedUpdates(() => {
                        for (const setIsLoading of Object.values(reader.setIsLoadings)) {
                            setIsLoading(true);
                        }
                    });
                    const queryResponses = resemble_react.grpcServerStream({
                        endpoint: `${this.endpoint}/__/resemble/resemble.cloud.v1alpha1.user.UsersInterface/${this.stateRef}/resemble.v1alpha1.React/Query`,
                        method: "POST",
                        headers,
                        request: queryRequest,
                        responseType: resemble_api.react_pb.QueryResponse,
                        signal: reader.abortController.signal,
                    });
                    for await (const queryResponse of queryResponses) {
                        if (!loaded) {
                            if ((this.loadingReaders -= 1) === 0) {
                                this.readersLoadedOrFailed();
                            }
                            loaded = true;
                        }
                        unstable_batchedUpdates(() => {
                            for (const setIsLoading of Object.values(reader.setIsLoadings)) {
                                setIsLoading(false);
                            }
                        });
                        const response = queryResponse.response !== undefined
                            ? responseType.fromBinary(queryResponse.response)
                            : undefined;
                        // If we were disconnected it must be that we've
                        // observed all `orphans` because we waited
                        // for any mutations to flush before we re-started to
                        // read.
                        if (orphans.length > 0) {
                            // We mark all mutations as observed except the
                            // last one which we also invoke all `setResponse`s.
                            // In this way we effectively create a barrier
                            // for all readers that will synchronize on the last
                            // mutation, but note that this still may lead
                            // to some partial state/response updates because
                            // one reader may have actually received a response
                            // while another reader got disconnected. While this
                            // is likely very rare, it is possible. Mitigating
                            // this issue is non-trivial and for now we have
                            // no plans to address it.
                            for (let i = 0; i < orphans.length - 1; i++) {
                                orphans[i].observed(() => { });
                            }
                            await orphans[orphans.length - 1].observed(() => {
                                if (response !== undefined) {
                                    reader.response = response;
                                    for (const setResponse of Object.values(reader.setResponses)) {
                                        setResponse(response);
                                    }
                                }
                            });
                            orphans = [];
                        }
                        else if (expecteds.length > 0 &&
                            queryResponse.idempotencyKeys.includes(expecteds[0].idempotencyKey)) {
                            // eslint-disable-next-line no-loop-func
                            await expecteds[0].observed(() => {
                                if (response !== undefined) {
                                    reader.response = response;
                                    for (const setResponse of Object.values(reader.setResponses)) {
                                        setResponse(response);
                                    }
                                }
                                // eslint-disable-next-line no-loop-func
                                expecteds.shift();
                            });
                        }
                        else if (response !== undefined) {
                            unstable_batchedUpdates(() => {
                                reader.response = response;
                                for (const setResponse of Object.values(reader.setResponses)) {
                                    setResponse(response);
                                }
                            });
                        }
                    }
                    throw new Error('Not expecting stream to ever be done');
                }
                catch (e) {
                    if (!loaded) {
                        if ((this.loadingReaders -= 1) === 0) {
                            this.readersLoadedOrFailed();
                        }
                    }
                    loaded = false;
                    if (reader.abortController.signal.aborted) {
                        for (const { aborted } of [...orphans, ...expecteds]) {
                            aborted();
                        }
                        return;
                    }
                    unstable_batchedUpdates(() => {
                        for (const setIsLoading of Object.values(reader.setIsLoadings)) {
                            setIsLoading(false);
                        }
                        if (e instanceof resemble_api.Status) {
                            for (const setStatus of Object.values(reader.setStatuses)) {
                                setStatus(e);
                            }
                        }
                    });
                    throw e; // This just retries!
                }
            });
        }
        finally {
            delete this.observers[id];
        }
    }
    async add(mutation) {
        // We always have at least 1 observer which is this function!
        let remainingObservers = 1;
        const event = new resemble_react.Event();
        const callbacks = [];
        const observed = (callback) => {
            callbacks.push(callback);
            remainingObservers -= 1;
            if (remainingObservers === 0) {
                unstable_batchedUpdates(() => {
                    for (const callback of callbacks) {
                        callback();
                    }
                });
                event.set();
            }
            return event.wait();
        };
        const aborted = () => {
            observed(() => { });
        };
        // Tell observers about this pending mutation.
        for (const id in this.observers) {
            remainingObservers += 1;
            this.observers[id].observe(mutation.idempotencyKey, observed, aborted);
        }
        this.useAddMutations.push(mutation);
        unstable_batchedUpdates(() => {
            for (const setPending of Object.values(this.useAddSetPendings)) {
                setPending(this.useAddMutations);
            }
        });
        return new Promise(async (resolve, reject) => {
            const { responseOrStatus } = await this.mutate({
                method: "Add",
                request: mutation.request.toBinary(),
                idempotencyKey: mutation.idempotencyKey,
                bearerToken: mutation.bearerToken,
            }, ({ isLoading, error }) => {
                let rerender = false;
                for (const m of this.useAddMutations) {
                    if (m === mutation) {
                        if (m.isLoading !== isLoading) {
                            m.isLoading = isLoading;
                            rerender = true;
                        }
                        if (error !== undefined && m.error !== error) {
                            m.error = error;
                            rerender = true;
                        }
                    }
                }
                if (rerender) {
                    unstable_batchedUpdates(() => {
                        for (const setPending of Object.values(this.useAddSetPendings)) {
                            setPending(this.useAddMutations);
                        }
                    });
                }
            });
            switch (responseOrStatus.case) {
                case "response":
                    await observed(() => {
                        this.useAddMutations =
                            this.useAddMutations.filter(m => m !== mutation);
                        unstable_batchedUpdates(() => {
                            for (const setPending of Object.values(this.useAddSetPendings)) {
                                setPending(this.useAddMutations);
                            }
                        });
                        resolve({
                            response: AddResponse.fromBinary(responseOrStatus.value)
                        });
                    });
                    break;
                case "status":
                    // Let the observers know they no longer should expect to
                    // observe this idempotency key.
                    for (const id in this.observers) {
                        this.observers[id].unobserve(mutation.idempotencyKey);
                    }
                    const status = resemble_api.Status.fromJsonString(responseOrStatus.value);
                    const aborted = UsersAddAborted.fromStatus(status);
                    console.warn(`'Users.Add' aborted with ${aborted.message}`);
                    resolve({ aborted });
                    break;
                default:
                    // TODO(benh): while this is a _really_ fatal error,
                    // should we still set `aborted` instead of throwing?
                    reject(new Error('Expecting either a response or a status'));
            }
        });
    }
    useAdd(id, setPending) {
        this.useAddSetPendings[id] = setPending;
    }
    unuseAdd(id) {
        delete this.useAddSetPendings[id];
    }
    static use(id, stateRef, endpoint) {
        if (!(id in this.instances)) {
            this.instances[id] = new UsersInstance(id, stateRef, endpoint);
        }
        else {
            this.instances[id].ref();
        }
        return this.instances[id];
    }
    unuse() {
        if (this.unref() === 0) {
            delete UsersInstance.instances[this.id];
        }
    }
}
UsersInstance.instances = {};
export const useUsers = ({ id }) => {
    const stateId = id;
    const stateRef = resemble_react.stateIdToRef("resemble.cloud.v1alpha1.user.Users", id);
    const resembleContext = resemble_react.useResembleContext();
    const endpoint = resembleContext.client.endpoint;
    const bearerToken = resembleContext.bearerToken;
    const [instance, setInstance] = useState(() => {
        return UsersInstance.use(stateId, stateRef, endpoint);
    });
    if (instance.id !== stateId) {
        setInstance(UsersInstance.use(stateId, stateRef, endpoint));
    }
    useEffect(() => {
        return () => {
            instance.unuse();
        };
    }, [instance]);
    const headers = useMemo(() => {
        const headers = new Headers();
        headers.set("Content-Type", "application/json");
        headers.append("Connection", "keep-alive");
        if (bearerToken !== undefined) {
            headers.append("Authorization", `Bearer ${bearerToken}`);
        }
        return headers;
    }, [bearerToken]);
    function useAdd() {
        const [pending, setPending] = useState([]);
        useEffect(() => {
            const id = uuidv4();
            instance.useAdd(id, setPending);
            return () => {
                instance.unuseAdd(id);
            };
        }, []);
        const resembleContext = resemble_react.useResembleContext();
        const bearerToken = resembleContext.bearerToken;
        const add = useMemo(() => {
            const method = async (partialRequest = {}, optimistic_metadata) => {
                const request = partialRequest instanceof AddRequest
                    ? partialRequest.clone()
                    : new AddRequest(partialRequest);
                const idempotencyKey = uuidv4();
                const mutation = {
                    request,
                    idempotencyKey,
                    bearerToken,
                    optimistic_metadata,
                    isLoading: false, // Won't start loading if we're flushing mutations.
                };
                return instance.add(mutation);
            };
            method.pending =
                new Array();
            return method;
        }, [bearerToken]);
        add.pending = pending;
        return add;
    }
    const add = useAdd();
    return {
        mutators: {
            add,
        },
        add,
    };
};
export class Users {
}
Users.State = UsersProto;
class UserInstance {
    constructor(id, stateRef, endpoint) {
        this.observers = {};
        this.loadingReaders = 0;
        this.runningMutates = [];
        this.queuedMutates = [];
        this.flushMutates = undefined;
        this.websocket = undefined;
        this.backoff = new resemble_react.Backoff();
        this.websocketsConnectionAbortController = new AbortController();
        this.useSignUpMutations = [];
        this.useSignUpSetPendings = {};
        this.useSetSurveyDataMutations = [];
        this.useSetSurveyDataSetPendings = {};
        this.useSetStatusMutations = [];
        this.useSetStatusSetPendings = {};
        this.useActivateTaskMutations = [];
        this.useActivateTaskSetPendings = {};
        this.useGetReaders = {};
        this.id = id;
        this.stateRef = stateRef;
        this.endpoint = endpoint;
        this.refs = 1;
        // TODO(benh): rather than keeping a long-lived open connection
        // via `WebSocketsConnection`, we could consider aborting that
        // connection once the websocket is established.
        this.initializeWebSocketsConnection();
        this.initializeWebSocket();
    }
    ref() {
        this.refs += 1;
        return this.refs;
    }
    unref() {
        this.refs -= 1;
        if (this.refs === 0 && this.websocket !== undefined) {
            this.websocket.close();
            this.websocketsConnectionAbortController.abort();
        }
        return this.refs;
    }
    hasRunningMutations() {
        return this.runningMutates.length > 0;
    }
    async flushMutations() {
        if (this.flushMutates === undefined) {
            this.flushMutates = new resemble_react.Event();
        }
        await this.flushMutates.wait();
    }
    readersLoadedOrFailed() {
        var _a;
        this.flushMutates = undefined;
        if (this.queuedMutates.length > 0) {
            this.runningMutates = this.queuedMutates;
            this.queuedMutates = [];
            if (((_a = this.websocket) === null || _a === void 0 ? void 0 : _a.readyState) === WebSocket.OPEN) {
                for (const { request, update } of this.runningMutates) {
                    update({ isLoading: true });
                    try {
                        this.websocket.send(request.toBinary());
                    }
                    catch (e) {
                        // We'll retry since we've stored in `*Mutates`.
                    }
                }
            }
        }
    }
    initializeWebSocketsConnection() {
        resemble_react.retryForever(async () => {
            const headers = new Headers();
            headers.set("Content-Type", "application/json");
            headers.append("Connection", "keep-alive");
            // NOTE: we use `fetch()` not `resemble_react.guardedFetch()`
            // because if there are only mutations than a disconnect will
            // cause us to show a warning when using `rsm dev` but we won't
            // ever remove the warning because the fetch to
            // `WebSocketsConnection` waits indefinitely.
            await fetch(new Request(`${this.endpoint}/__/resemble/resemble.cloud.v1alpha1.user.UserInterface/${this.stateRef}/resemble.v1alpha1.React/WebSocketsConnection`, {
                method: "POST",
                headers,
                body: new resemble_api.react_pb.WebSocketsConnectionRequest()
                    .toJsonString(),
            }), { signal: this.websocketsConnectionAbortController.signal }).catch((error) => {
                // In some legitimate cases,
                // this.websocketsConnectionAbortController.abort() will be called
                // before fetch() finishes, like on quick unmount.
                // Suppress this error, reraise everything else.
                if (error instanceof Error && error.name !== "AbortError") {
                    throw (error);
                }
            });
        });
    }
    initializeWebSocket() {
        if (this.websocket === undefined && this.refs > 0) {
            const url = new URL(this.endpoint);
            const protocol = url.protocol === "https:" ? "wss:" : "ws:";
            this.websocket = new WebSocket(`${protocol}//${url.host}/__/resemble/resemble.cloud.v1alpha1.user.UserInterface/${this.stateRef}`);
            this.websocket.binaryType = "arraybuffer";
            this.websocket.onopen = (event) => {
                var _a;
                if (((_a = this.websocket) === null || _a === void 0 ? void 0 : _a.readyState) === WebSocket.OPEN) {
                    for (const { request, update } of this.runningMutates) {
                        update({ isLoading: true });
                        try {
                            this.websocket.send(request.toBinary());
                        }
                        catch (e) {
                            // We'll retry since we've stored in `*Mutates`.
                        }
                    }
                }
            };
            this.websocket.onerror = async (event) => {
                if (this.websocket !== undefined) {
                    // TODO: explicitly close?
                    this.websocket = undefined;
                    for (const { update } of this.runningMutates) {
                        update({ isLoading: false, error: "WebSocket disconnected" });
                    }
                    if (this.refs > 0) {
                        if (this.runningMutates.length > 0) {
                            console.warn(`WebSocket disconnected, ${this.runningMutates.length} outstanding mutations will be retried when we reconnect`);
                        }
                        await this.backoff.wait();
                        this.initializeWebSocket();
                    }
                }
            };
            this.websocket.onclose = async (event) => {
                if (this.websocket !== undefined) {
                    // TODO: explicitly close?
                    this.websocket = undefined;
                    for (const { update } of this.runningMutates) {
                        update({ isLoading: false, error: "WebSocket disconnected" });
                    }
                    if (this.refs > 0) {
                        await this.backoff.wait();
                        this.initializeWebSocket();
                    }
                }
            };
            this.websocket.onmessage = async (event) => {
                const { resolve } = this.runningMutates[0];
                this.runningMutates.shift();
                const response = resemble_api.react_pb.MutateResponse.fromBinary(new Uint8Array(event.data));
                resolve(response);
                if (this.flushMutates !== undefined &&
                    this.runningMutates.length === 0) {
                    this.flushMutates.set();
                }
            };
        }
    }
    async mutate(partialRequest, update) {
        const request = partialRequest instanceof resemble_api.react_pb.MutateRequest
            ? partialRequest
            : new resemble_api.react_pb.MutateRequest(partialRequest);
        return new Promise((resolve, _) => {
            var _a;
            if (this.loadingReaders === 0) {
                this.runningMutates.push({ request, resolve, update });
                if (((_a = this.websocket) === null || _a === void 0 ? void 0 : _a.readyState) === WebSocket.OPEN) {
                    update({ isLoading: true });
                    try {
                        this.websocket.send(request.toBinary());
                    }
                    catch (e) {
                        // We'll retry since we've stored in `*Mutates`.
                    }
                }
            }
            else {
                this.queuedMutates.push({ request, resolve, update });
            }
        });
    }
    async read(method, request, bearerToken, responseType, reader) {
        const headers = new Headers();
        headers.set("Content-Type", "application/json");
        headers.append("Connection", "keep-alive");
        if (bearerToken !== undefined) {
            headers.append("Authorization", `Bearer ${bearerToken}`);
        }
        const queryRequest = new resemble_api.react_pb.QueryRequest({
            method,
            request: request.toBinary(),
        });
        let expecteds = [];
        // When we disconnect we may not be able to observe
        // responses due to mutations yet there may still be
        // some outstanding responses that are expected which
        // we treat as "orphans" in the sense that we won't
        // observe their idempotency keys but once we reconnect
        // we will still have observed their effects and can
        // call `observed()` on them.
        let orphans = [];
        const id = `${uuidv4()}`;
        this.observers[id] = {
            observe: (idempotencyKey, observed, aborted) => {
                expecteds.push({ idempotencyKey, observed, aborted });
            },
            unobserve: (idempotencyKey) => {
                expecteds = expecteds.filter(expected => expected.idempotencyKey !== idempotencyKey);
            }
        };
        try {
            await resemble_react.retryForever(async () => {
                let loaded = false;
                this.loadingReaders += 1;
                // Any mutations started after we've incremented
                // `this.loadingReaders` will be queued until after
                // all the readers have loaded and thus (1) we know all
                // current `expected` are actually `orphans` that
                // we will haved "observed" once we are (re)connected
                // because we flush mutations before starting to read
                // and (2) all queued mutations can stay in `expected`
                // because we will in fact be able to observe them
                // since they won't get sent over the websocket
                // until after we are (re)connected.
                //
                // NOTE: we need to concatenate with `orphans`
                // because we may try to (re)connect multiple times
                // and between each try more mutations may have been
                // made (or queued ones will be moved to running).
                orphans = [...orphans, ...expecteds];
                expecteds = [];
                try {
                    // Wait for potentially completed mutations to flush
                    // before starting to read so that we read the latest
                    // state including those mutations.
                    if (this.hasRunningMutations()) {
                        await this.flushMutations();
                    }
                    unstable_batchedUpdates(() => {
                        for (const setIsLoading of Object.values(reader.setIsLoadings)) {
                            setIsLoading(true);
                        }
                    });
                    const queryResponses = resemble_react.grpcServerStream({
                        endpoint: `${this.endpoint}/__/resemble/resemble.cloud.v1alpha1.user.UserInterface/${this.stateRef}/resemble.v1alpha1.React/Query`,
                        method: "POST",
                        headers,
                        request: queryRequest,
                        responseType: resemble_api.react_pb.QueryResponse,
                        signal: reader.abortController.signal,
                    });
                    for await (const queryResponse of queryResponses) {
                        if (!loaded) {
                            if ((this.loadingReaders -= 1) === 0) {
                                this.readersLoadedOrFailed();
                            }
                            loaded = true;
                        }
                        unstable_batchedUpdates(() => {
                            for (const setIsLoading of Object.values(reader.setIsLoadings)) {
                                setIsLoading(false);
                            }
                        });
                        const response = queryResponse.response !== undefined
                            ? responseType.fromBinary(queryResponse.response)
                            : undefined;
                        // If we were disconnected it must be that we've
                        // observed all `orphans` because we waited
                        // for any mutations to flush before we re-started to
                        // read.
                        if (orphans.length > 0) {
                            // We mark all mutations as observed except the
                            // last one which we also invoke all `setResponse`s.
                            // In this way we effectively create a barrier
                            // for all readers that will synchronize on the last
                            // mutation, but note that this still may lead
                            // to some partial state/response updates because
                            // one reader may have actually received a response
                            // while another reader got disconnected. While this
                            // is likely very rare, it is possible. Mitigating
                            // this issue is non-trivial and for now we have
                            // no plans to address it.
                            for (let i = 0; i < orphans.length - 1; i++) {
                                orphans[i].observed(() => { });
                            }
                            await orphans[orphans.length - 1].observed(() => {
                                if (response !== undefined) {
                                    reader.response = response;
                                    for (const setResponse of Object.values(reader.setResponses)) {
                                        setResponse(response);
                                    }
                                }
                            });
                            orphans = [];
                        }
                        else if (expecteds.length > 0 &&
                            queryResponse.idempotencyKeys.includes(expecteds[0].idempotencyKey)) {
                            // eslint-disable-next-line no-loop-func
                            await expecteds[0].observed(() => {
                                if (response !== undefined) {
                                    reader.response = response;
                                    for (const setResponse of Object.values(reader.setResponses)) {
                                        setResponse(response);
                                    }
                                }
                                // eslint-disable-next-line no-loop-func
                                expecteds.shift();
                            });
                        }
                        else if (response !== undefined) {
                            unstable_batchedUpdates(() => {
                                reader.response = response;
                                for (const setResponse of Object.values(reader.setResponses)) {
                                    setResponse(response);
                                }
                            });
                        }
                    }
                    throw new Error('Not expecting stream to ever be done');
                }
                catch (e) {
                    if (!loaded) {
                        if ((this.loadingReaders -= 1) === 0) {
                            this.readersLoadedOrFailed();
                        }
                    }
                    loaded = false;
                    if (reader.abortController.signal.aborted) {
                        for (const { aborted } of [...orphans, ...expecteds]) {
                            aborted();
                        }
                        return;
                    }
                    unstable_batchedUpdates(() => {
                        for (const setIsLoading of Object.values(reader.setIsLoadings)) {
                            setIsLoading(false);
                        }
                        if (e instanceof resemble_api.Status) {
                            for (const setStatus of Object.values(reader.setStatuses)) {
                                setStatus(e);
                            }
                        }
                    });
                    throw e; // This just retries!
                }
            });
        }
        finally {
            delete this.observers[id];
        }
    }
    async signUp(mutation) {
        // We always have at least 1 observer which is this function!
        let remainingObservers = 1;
        const event = new resemble_react.Event();
        const callbacks = [];
        const observed = (callback) => {
            callbacks.push(callback);
            remainingObservers -= 1;
            if (remainingObservers === 0) {
                unstable_batchedUpdates(() => {
                    for (const callback of callbacks) {
                        callback();
                    }
                });
                event.set();
            }
            return event.wait();
        };
        const aborted = () => {
            observed(() => { });
        };
        // Tell observers about this pending mutation.
        for (const id in this.observers) {
            remainingObservers += 1;
            this.observers[id].observe(mutation.idempotencyKey, observed, aborted);
        }
        this.useSignUpMutations.push(mutation);
        unstable_batchedUpdates(() => {
            for (const setPending of Object.values(this.useSignUpSetPendings)) {
                setPending(this.useSignUpMutations);
            }
        });
        return new Promise(async (resolve, reject) => {
            const { responseOrStatus } = await this.mutate({
                method: "SignUp",
                request: mutation.request.toBinary(),
                idempotencyKey: mutation.idempotencyKey,
                bearerToken: mutation.bearerToken,
            }, ({ isLoading, error }) => {
                let rerender = false;
                for (const m of this.useSignUpMutations) {
                    if (m === mutation) {
                        if (m.isLoading !== isLoading) {
                            m.isLoading = isLoading;
                            rerender = true;
                        }
                        if (error !== undefined && m.error !== error) {
                            m.error = error;
                            rerender = true;
                        }
                    }
                }
                if (rerender) {
                    unstable_batchedUpdates(() => {
                        for (const setPending of Object.values(this.useSignUpSetPendings)) {
                            setPending(this.useSignUpMutations);
                        }
                    });
                }
            });
            switch (responseOrStatus.case) {
                case "response":
                    await observed(() => {
                        this.useSignUpMutations =
                            this.useSignUpMutations.filter(m => m !== mutation);
                        unstable_batchedUpdates(() => {
                            for (const setPending of Object.values(this.useSignUpSetPendings)) {
                                setPending(this.useSignUpMutations);
                            }
                        });
                        resolve({
                            response: SignUpResponse.fromBinary(responseOrStatus.value)
                        });
                    });
                    break;
                case "status":
                    // Let the observers know they no longer should expect to
                    // observe this idempotency key.
                    for (const id in this.observers) {
                        this.observers[id].unobserve(mutation.idempotencyKey);
                    }
                    const status = resemble_api.Status.fromJsonString(responseOrStatus.value);
                    const aborted = UserSignUpAborted.fromStatus(status);
                    console.warn(`'User.SignUp' aborted with ${aborted.message}`);
                    resolve({ aborted });
                    break;
                default:
                    // TODO(benh): while this is a _really_ fatal error,
                    // should we still set `aborted` instead of throwing?
                    reject(new Error('Expecting either a response or a status'));
            }
        });
    }
    useSignUp(id, setPending) {
        this.useSignUpSetPendings[id] = setPending;
    }
    unuseSignUp(id) {
        delete this.useSignUpSetPendings[id];
    }
    async setSurveyData(mutation) {
        // We always have at least 1 observer which is this function!
        let remainingObservers = 1;
        const event = new resemble_react.Event();
        const callbacks = [];
        const observed = (callback) => {
            callbacks.push(callback);
            remainingObservers -= 1;
            if (remainingObservers === 0) {
                unstable_batchedUpdates(() => {
                    for (const callback of callbacks) {
                        callback();
                    }
                });
                event.set();
            }
            return event.wait();
        };
        const aborted = () => {
            observed(() => { });
        };
        // Tell observers about this pending mutation.
        for (const id in this.observers) {
            remainingObservers += 1;
            this.observers[id].observe(mutation.idempotencyKey, observed, aborted);
        }
        this.useSetSurveyDataMutations.push(mutation);
        unstable_batchedUpdates(() => {
            for (const setPending of Object.values(this.useSetSurveyDataSetPendings)) {
                setPending(this.useSetSurveyDataMutations);
            }
        });
        return new Promise(async (resolve, reject) => {
            const { responseOrStatus } = await this.mutate({
                method: "SetSurveyData",
                request: mutation.request.toBinary(),
                idempotencyKey: mutation.idempotencyKey,
                bearerToken: mutation.bearerToken,
            }, ({ isLoading, error }) => {
                let rerender = false;
                for (const m of this.useSetSurveyDataMutations) {
                    if (m === mutation) {
                        if (m.isLoading !== isLoading) {
                            m.isLoading = isLoading;
                            rerender = true;
                        }
                        if (error !== undefined && m.error !== error) {
                            m.error = error;
                            rerender = true;
                        }
                    }
                }
                if (rerender) {
                    unstable_batchedUpdates(() => {
                        for (const setPending of Object.values(this.useSetSurveyDataSetPendings)) {
                            setPending(this.useSetSurveyDataMutations);
                        }
                    });
                }
            });
            switch (responseOrStatus.case) {
                case "response":
                    await observed(() => {
                        this.useSetSurveyDataMutations =
                            this.useSetSurveyDataMutations.filter(m => m !== mutation);
                        unstable_batchedUpdates(() => {
                            for (const setPending of Object.values(this.useSetSurveyDataSetPendings)) {
                                setPending(this.useSetSurveyDataMutations);
                            }
                        });
                        resolve({
                            response: SetSurveyDataResponse.fromBinary(responseOrStatus.value)
                        });
                    });
                    break;
                case "status":
                    // Let the observers know they no longer should expect to
                    // observe this idempotency key.
                    for (const id in this.observers) {
                        this.observers[id].unobserve(mutation.idempotencyKey);
                    }
                    const status = resemble_api.Status.fromJsonString(responseOrStatus.value);
                    const aborted = UserSetSurveyDataAborted.fromStatus(status);
                    console.warn(`'User.SetSurveyData' aborted with ${aborted.message}`);
                    resolve({ aborted });
                    break;
                default:
                    // TODO(benh): while this is a _really_ fatal error,
                    // should we still set `aborted` instead of throwing?
                    reject(new Error('Expecting either a response or a status'));
            }
        });
    }
    useSetSurveyData(id, setPending) {
        this.useSetSurveyDataSetPendings[id] = setPending;
    }
    unuseSetSurveyData(id) {
        delete this.useSetSurveyDataSetPendings[id];
    }
    async setStatus(mutation) {
        // We always have at least 1 observer which is this function!
        let remainingObservers = 1;
        const event = new resemble_react.Event();
        const callbacks = [];
        const observed = (callback) => {
            callbacks.push(callback);
            remainingObservers -= 1;
            if (remainingObservers === 0) {
                unstable_batchedUpdates(() => {
                    for (const callback of callbacks) {
                        callback();
                    }
                });
                event.set();
            }
            return event.wait();
        };
        const aborted = () => {
            observed(() => { });
        };
        // Tell observers about this pending mutation.
        for (const id in this.observers) {
            remainingObservers += 1;
            this.observers[id].observe(mutation.idempotencyKey, observed, aborted);
        }
        this.useSetStatusMutations.push(mutation);
        unstable_batchedUpdates(() => {
            for (const setPending of Object.values(this.useSetStatusSetPendings)) {
                setPending(this.useSetStatusMutations);
            }
        });
        return new Promise(async (resolve, reject) => {
            const { responseOrStatus } = await this.mutate({
                method: "SetStatus",
                request: mutation.request.toBinary(),
                idempotencyKey: mutation.idempotencyKey,
                bearerToken: mutation.bearerToken,
            }, ({ isLoading, error }) => {
                let rerender = false;
                for (const m of this.useSetStatusMutations) {
                    if (m === mutation) {
                        if (m.isLoading !== isLoading) {
                            m.isLoading = isLoading;
                            rerender = true;
                        }
                        if (error !== undefined && m.error !== error) {
                            m.error = error;
                            rerender = true;
                        }
                    }
                }
                if (rerender) {
                    unstable_batchedUpdates(() => {
                        for (const setPending of Object.values(this.useSetStatusSetPendings)) {
                            setPending(this.useSetStatusMutations);
                        }
                    });
                }
            });
            switch (responseOrStatus.case) {
                case "response":
                    await observed(() => {
                        this.useSetStatusMutations =
                            this.useSetStatusMutations.filter(m => m !== mutation);
                        unstable_batchedUpdates(() => {
                            for (const setPending of Object.values(this.useSetStatusSetPendings)) {
                                setPending(this.useSetStatusMutations);
                            }
                        });
                        resolve({
                            response: SetStatusResponse.fromBinary(responseOrStatus.value)
                        });
                    });
                    break;
                case "status":
                    // Let the observers know they no longer should expect to
                    // observe this idempotency key.
                    for (const id in this.observers) {
                        this.observers[id].unobserve(mutation.idempotencyKey);
                    }
                    const status = resemble_api.Status.fromJsonString(responseOrStatus.value);
                    const aborted = UserSetStatusAborted.fromStatus(status);
                    console.warn(`'User.SetStatus' aborted with ${aborted.message}`);
                    resolve({ aborted });
                    break;
                default:
                    // TODO(benh): while this is a _really_ fatal error,
                    // should we still set `aborted` instead of throwing?
                    reject(new Error('Expecting either a response or a status'));
            }
        });
    }
    useSetStatus(id, setPending) {
        this.useSetStatusSetPendings[id] = setPending;
    }
    unuseSetStatus(id) {
        delete this.useSetStatusSetPendings[id];
    }
    async activateTask(mutation) {
        // We always have at least 1 observer which is this function!
        let remainingObservers = 1;
        const event = new resemble_react.Event();
        const callbacks = [];
        const observed = (callback) => {
            callbacks.push(callback);
            remainingObservers -= 1;
            if (remainingObservers === 0) {
                unstable_batchedUpdates(() => {
                    for (const callback of callbacks) {
                        callback();
                    }
                });
                event.set();
            }
            return event.wait();
        };
        const aborted = () => {
            observed(() => { });
        };
        // Tell observers about this pending mutation.
        for (const id in this.observers) {
            remainingObservers += 1;
            this.observers[id].observe(mutation.idempotencyKey, observed, aborted);
        }
        this.useActivateTaskMutations.push(mutation);
        unstable_batchedUpdates(() => {
            for (const setPending of Object.values(this.useActivateTaskSetPendings)) {
                setPending(this.useActivateTaskMutations);
            }
        });
        return new Promise(async (resolve, reject) => {
            const { responseOrStatus } = await this.mutate({
                method: "ActivateTask",
                request: mutation.request.toBinary(),
                idempotencyKey: mutation.idempotencyKey,
                bearerToken: mutation.bearerToken,
            }, ({ isLoading, error }) => {
                let rerender = false;
                for (const m of this.useActivateTaskMutations) {
                    if (m === mutation) {
                        if (m.isLoading !== isLoading) {
                            m.isLoading = isLoading;
                            rerender = true;
                        }
                        if (error !== undefined && m.error !== error) {
                            m.error = error;
                            rerender = true;
                        }
                    }
                }
                if (rerender) {
                    unstable_batchedUpdates(() => {
                        for (const setPending of Object.values(this.useActivateTaskSetPendings)) {
                            setPending(this.useActivateTaskMutations);
                        }
                    });
                }
            });
            switch (responseOrStatus.case) {
                case "response":
                    await observed(() => {
                        this.useActivateTaskMutations =
                            this.useActivateTaskMutations.filter(m => m !== mutation);
                        unstable_batchedUpdates(() => {
                            for (const setPending of Object.values(this.useActivateTaskSetPendings)) {
                                setPending(this.useActivateTaskMutations);
                            }
                        });
                        resolve({
                            response: ActivateTaskResponse.fromBinary(responseOrStatus.value)
                        });
                    });
                    break;
                case "status":
                    // Let the observers know they no longer should expect to
                    // observe this idempotency key.
                    for (const id in this.observers) {
                        this.observers[id].unobserve(mutation.idempotencyKey);
                    }
                    const status = resemble_api.Status.fromJsonString(responseOrStatus.value);
                    const aborted = UserActivateTaskAborted.fromStatus(status);
                    console.warn(`'User.ActivateTask' aborted with ${aborted.message}`);
                    resolve({ aborted });
                    break;
                default:
                    // TODO(benh): while this is a _really_ fatal error,
                    // should we still set `aborted` instead of throwing?
                    reject(new Error('Expecting either a response or a status'));
            }
        });
    }
    useActivateTask(id, setPending) {
        this.useActivateTaskSetPendings[id] = setPending;
    }
    unuseActivateTask(id) {
        delete this.useActivateTaskSetPendings[id];
    }
    useGet(id, request, bearerToken, setResponse, setIsLoading, setStatus) {
        let read = false;
        // NOTE: need to concatenate `request.toJsonString()` with `bearerToken`
        // because it uniquely identifies the request, i.e., a second call
        // that has the same `request` but a different bearerToken should be a
        // different call.
        const key = request.toJsonString() + bearerToken;
        if (!(key in this.useGetReaders)) {
            this.useGetReaders[key] = {
                abortController: new AbortController(),
                setResponses: {},
                setIsLoadings: {},
                setStatuses: {},
            };
            read = true;
        }
        let reader = this.useGetReaders[key];
        reader.setResponses[id] = setResponse;
        reader.setIsLoadings[id] = setIsLoading;
        reader.setStatuses[id] = setStatus;
        if (reader.response !== undefined) {
            setResponse(reader.response);
        }
        if (read) {
            this.read("Get", request, bearerToken, GetResponse, reader);
        }
    }
    unuseGet(id, request, bearerToken) {
        // See comment above in `useGet` for why
        // we concatenate `request.toJsonString()` with `bearerToken`.
        const key = request.toJsonString() + bearerToken;
        const reader = this.useGetReaders[key];
        delete reader.setResponses[id];
        delete reader.setIsLoadings[id];
        delete reader.setStatuses[id];
        if (Object.values(reader.setResponses).length === 0) {
            delete this.useGetReaders[key];
            reader.abortController.abort();
        }
    }
    static use(id, stateRef, endpoint) {
        if (!(id in this.instances)) {
            this.instances[id] = new UserInstance(id, stateRef, endpoint);
        }
        else {
            this.instances[id].ref();
        }
        return this.instances[id];
    }
    unuse() {
        if (this.unref() === 0) {
            delete UserInstance.instances[this.id];
        }
    }
}
UserInstance.instances = {};
export const useUser = ({ id }) => {
    const stateId = id;
    const stateRef = resemble_react.stateIdToRef("resemble.cloud.v1alpha1.user.User", id);
    const resembleContext = resemble_react.useResembleContext();
    const endpoint = resembleContext.client.endpoint;
    const bearerToken = resembleContext.bearerToken;
    const [instance, setInstance] = useState(() => {
        return UserInstance.use(stateId, stateRef, endpoint);
    });
    if (instance.id !== stateId) {
        setInstance(UserInstance.use(stateId, stateRef, endpoint));
    }
    useEffect(() => {
        return () => {
            instance.unuse();
        };
    }, [instance]);
    const headers = useMemo(() => {
        const headers = new Headers();
        headers.set("Content-Type", "application/json");
        headers.append("Connection", "keep-alive");
        if (bearerToken !== undefined) {
            headers.append("Authorization", `Bearer ${bearerToken}`);
        }
        return headers;
    }, [bearerToken]);
    function useSignUp() {
        const [pending, setPending] = useState([]);
        useEffect(() => {
            const id = uuidv4();
            instance.useSignUp(id, setPending);
            return () => {
                instance.unuseSignUp(id);
            };
        }, []);
        const resembleContext = resemble_react.useResembleContext();
        const bearerToken = resembleContext.bearerToken;
        const signUp = useMemo(() => {
            const method = async (partialRequest = {}, optimistic_metadata) => {
                const request = partialRequest instanceof SignUpRequest
                    ? partialRequest.clone()
                    : new SignUpRequest(partialRequest);
                const idempotencyKey = uuidv4();
                const mutation = {
                    request,
                    idempotencyKey,
                    bearerToken,
                    optimistic_metadata,
                    isLoading: false, // Won't start loading if we're flushing mutations.
                };
                return instance.signUp(mutation);
            };
            method.pending =
                new Array();
            return method;
        }, [bearerToken]);
        signUp.pending = pending;
        return signUp;
    }
    const signUp = useSignUp();
    function useSetSurveyData() {
        const [pending, setPending] = useState([]);
        useEffect(() => {
            const id = uuidv4();
            instance.useSetSurveyData(id, setPending);
            return () => {
                instance.unuseSetSurveyData(id);
            };
        }, []);
        const resembleContext = resemble_react.useResembleContext();
        const bearerToken = resembleContext.bearerToken;
        const setSurveyData = useMemo(() => {
            const method = async (partialRequest = {}, optimistic_metadata) => {
                const request = partialRequest instanceof SetSurveyDataRequest
                    ? partialRequest.clone()
                    : new SetSurveyDataRequest(partialRequest);
                const idempotencyKey = uuidv4();
                const mutation = {
                    request,
                    idempotencyKey,
                    bearerToken,
                    optimistic_metadata,
                    isLoading: false, // Won't start loading if we're flushing mutations.
                };
                return instance.setSurveyData(mutation);
            };
            method.pending =
                new Array();
            return method;
        }, [bearerToken]);
        setSurveyData.pending = pending;
        return setSurveyData;
    }
    const setSurveyData = useSetSurveyData();
    function useSetStatus() {
        const [pending, setPending] = useState([]);
        useEffect(() => {
            const id = uuidv4();
            instance.useSetStatus(id, setPending);
            return () => {
                instance.unuseSetStatus(id);
            };
        }, []);
        const resembleContext = resemble_react.useResembleContext();
        const bearerToken = resembleContext.bearerToken;
        const setStatus = useMemo(() => {
            const method = async (partialRequest = {}, optimistic_metadata) => {
                const request = partialRequest instanceof SetStatusRequest
                    ? partialRequest.clone()
                    : new SetStatusRequest(partialRequest);
                const idempotencyKey = uuidv4();
                const mutation = {
                    request,
                    idempotencyKey,
                    bearerToken,
                    optimistic_metadata,
                    isLoading: false, // Won't start loading if we're flushing mutations.
                };
                return instance.setStatus(mutation);
            };
            method.pending =
                new Array();
            return method;
        }, [bearerToken]);
        setStatus.pending = pending;
        return setStatus;
    }
    const setStatus = useSetStatus();
    function useActivateTask() {
        const [pending, setPending] = useState([]);
        useEffect(() => {
            const id = uuidv4();
            instance.useActivateTask(id, setPending);
            return () => {
                instance.unuseActivateTask(id);
            };
        }, []);
        const resembleContext = resemble_react.useResembleContext();
        const bearerToken = resembleContext.bearerToken;
        const activateTask = useMemo(() => {
            const method = async (partialRequest = {}, optimistic_metadata) => {
                const request = partialRequest instanceof Empty
                    ? partialRequest.clone()
                    : new Empty(partialRequest);
                const idempotencyKey = uuidv4();
                const mutation = {
                    request,
                    idempotencyKey,
                    bearerToken,
                    optimistic_metadata,
                    isLoading: false, // Won't start loading if we're flushing mutations.
                };
                return instance.activateTask(mutation);
            };
            method.pending =
                new Array();
            return method;
        }, [bearerToken]);
        activateTask.pending = pending;
        return activateTask;
    }
    const activateTask = useActivateTask();
    function useGet(partialRequest = {}) {
        const newRequest = partialRequest instanceof GetRequest
            ? partialRequest.clone()
            : new GetRequest(partialRequest);
        const [request, setRequest] = useState(newRequest);
        if (!request.equals(newRequest)) {
            setRequest(newRequest);
        }
        const [response, setResponse] = useState();
        const [isLoading, setIsLoading] = useState(true);
        const [aborted, setAborted] = useState();
        const resembleContext = resemble_react.useResembleContext();
        const bearerToken = resembleContext.bearerToken;
        useEffect(() => {
            const id = uuidv4();
            instance.useGet(id, request, bearerToken, (response) => {
                unstable_batchedUpdates(() => {
                    setAborted(undefined);
                    setResponse(response);
                });
            }, setIsLoading, (status) => {
                const aborted = UserGetAborted.fromStatus(status);
                console.warn(`'User.Get' aborted with ${aborted.message}`);
                setAborted(aborted);
            });
            return () => {
                instance.unuseGet(id, request, bearerToken);
            };
        }, [request, bearerToken]);
        return { response, isLoading, aborted };
    }
    async function get(partialRequest = {}) {
        const request = partialRequest instanceof GetRequest
            ? partialRequest.clone()
            : new GetRequest(partialRequest);
        // Fetch with retry, using a backoff, i.e., if we get disconnected.
        const response = await (async () => {
            const backoff = new resemble_react.Backoff();
            while (true) {
                try {
                    // Invariant here is that we use the '/package.service.method' path and
                    // HTTP 'POST' method (we need 'POST' because we send an HTTP body).
                    //
                    // See also 'resemble/helpers.py'.
                    return await resemble_react.guardedFetch(new Request(`${resembleContext.client.endpoint}/__/resemble/resemble.cloud.v1alpha1.user.UserInterface/${stateRef}/resemble.cloud.v1alpha1.user.UserInterface/Get`, {
                        method: "POST",
                        headers,
                        body: request.toJsonString()
                    }));
                }
                catch (e) {
                    if (e instanceof Error) {
                        console.error(e.message);
                    }
                    else {
                        console.error(`Unknown error: ${JSON.stringify(e)}`);
                    }
                }
                await backoff.wait();
            }
        })();
        if (!response.ok) {
            if (response.headers.get("content-type") === "application/json") {
                const status = resemble_api.Status.fromJson(await response.json());
                const aborted = UserGetAborted.fromStatus(status);
                console.warn(`'User.Get' aborted with ${aborted.message}`);
                return { aborted };
            }
            else {
                const aborted = new UserGetAborted(new resemble_api.errors_pb.Unknown(), {
                    message: `Unknown error with HTTP status ${response.status}`
                });
                return { aborted };
            }
        }
        else {
            return { response: await response.json() };
        }
    }
    return {
        mutators: {
            signUp,
            setSurveyData,
            setStatus,
            activateTask,
        },
        signUp,
        setSurveyData,
        setStatus,
        activateTask,
        get,
        useGet,
    };
};
export class User {
}
User.State = UserProto;
